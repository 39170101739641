import { getRoutePath } from 'config/routes';
import { useFlashScoreSidebarState } from 'features/aiWriter/AiWriterSidebar/steps/flashScore/useFlashScoreSidebarState';
import { EditorProvider } from 'features/aiWriter/AiWriterTextEditor/EditorProvider/EditorProvider';
import { AiWriterWorkspace } from 'features/aiWriter/AiWriterWorkspace/AiWriterWorkspace';
import {
  getActiveTab,
  getIsTabSelected,
  getTabSharePermission
} from 'features/aiWriter/store/selectors';
import { aiWriterWithoutNewDocumentParameterName } from 'features/homePage/chatForm/CreateChatForm';
import { LazyLoadedLoadingAnimation } from 'features/loading-spinner/LazyLoadedLoadingAnimation';
import { useNavigateToOnboarding } from 'features/onboardingJourney/useNavigateToOnboarding';
import { useEnableSidebarOnMount } from 'features/pageSidebar/useSidebarStore';
import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { changeUrlWithoutReload } from 'utils/changeUrlWithoutReload';

export const aiWriterPlateId = 'aiWriter';

export const AiWriter = () => {
  const isTabSelected = useAppSelector(getIsTabSelected);
  const tab = useAppSelector(getActiveTab);
  const tabSharePermission = useAppSelector(getTabSharePermission);
  const userId = useAppSelector(state => state.customer.id);
  const resetFlashScoreSidebarState = useFlashScoreSidebarState(state => state.reset);

  const [pageParameters] = useSearchParams();

  const isTeamShared = tabSharePermission === 'team';
  const isUserOwner = tab?.authorId === userId;
  const isDocumentReadonly = isTeamShared && !isUserOwner;

  useEnableSidebarOnMount(true);
  // TODO: look into this once again
  // useClearCurrentTabOnPageLeave();

  useNavigateToOnboarding();

  // Reset FlashScore on mount if no tab is selected
  useEffect(() => {
    if (!isTabSelected) {
      resetFlashScoreSidebarState();
    }
  }, [isTabSelected, resetFlashScoreSidebarState]);

  if (isTabSelected) {
    return (
      <EditorProvider readonly={isDocumentReadonly}>
        <AiWriterWorkspace readonly={isDocumentReadonly} />
      </EditorProvider>
    );
  }

  if (pageParameters.get(aiWriterWithoutNewDocumentParameterName)) {
    changeUrlWithoutReload(getRoutePath('newDocument'));
    return (
      <AnimationContainer>
        <LazyLoadedLoadingAnimation />
      </AnimationContainer>
    );
  }

  return <Navigate to={{ pathname: getRoutePath('newDocument') }} />;
};

const AnimationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
